<template>
  <div class="regions">
    <!-- Create or Edit Region -->
    <create-edit-region
      v-if="updatingRegion"
      :region="selectedRegion"
      @back="() => { updatingRegion = false }"
      @region-updated="onRegionUpdated"
    />

    <!-- Create or Edit Coverage Plans -->
    <create-edit-coverage-plans
      v-else-if="updatingCoveragePlans"
      :selected-region="selectedRegion"
      @coverage-updated="() => { updatingCoveragePlans = false; getRegions() }"
      @update-coverage-types="() => { updatingCoveragePlans = false; $emit('update-coverage-types') }"
      @back="() => { updatingCoveragePlans = false }"
    />

    <!-- Regions -->
    <b-card v-else>
      <div class="d-flex justify-content-between align-items-baseline mb-2">
        <h4>Regions</h4>

        <div class="d-flex">
          <b-form-group>
            <b-input-group>
              <template #prepend>
                <b-input-group-text>
                  <feather-icon icon="SearchIcon" />
                </b-input-group-text>
              </template>
              <b-form-input
                v-model="searchKeyword"
                type="text"
                placeholder="Search Regions"
                @input="searchRegion"
              />
            </b-input-group>
          </b-form-group>
          <div class="ml-2">
            <b-button
              variant="primary"
              @click="() => { updatingRegion = true }"
            >
              <feather-icon
                icon="PlusIcon"
              />
              Add New Region
            </b-button>
          </div>
        </div>
      </div>

      <b-overlay :show="isLoading">
        <b-table
          id="regions-table"
          :items="regions"
          :fields="fields"
          striped
          responsive
          selectable
          show-empty
          @row-clicked="row => showDetails(row)"
        >
          <template #cell(id)="data">
            {{ data.index + 1 }}
            <feather-icon
              v-if="data.item.coverages.total"
              :icon="data.detailsShowing ? 'ChevronUpIcon' : 'ChevronDownIcon'"
              class="ml-1"
            />
          </template>
          <template #head()="data">
            <span class="text-capitalize">{{ data.label }}</span>
          </template>

          <!-- Coverage Types -->
          <template #cell(coverage_types)="data">
            <span class="ml-4">{{ data.item.coverageCategories.total }}</span>
          </template>

          <!-- Actions -->
          <template #cell(actions)="data">
            <b-dropdown
              variant="link"
              no-caret
            >
              <template #button-content>
                <feather-icon icon="MoreVerticalIcon" />
              </template>

              <b-dropdown-item
                @click="showCoverageModal(data.item)"
              >
                <feather-icon icon="EditIcon" />
                Update Coverage Plans
              </b-dropdown-item>

              <b-dropdown-item
                @click="showEditRegionModal(data.item)"
              >
                <feather-icon icon="Edit2Icon" />
                Edit Region
              </b-dropdown-item>
            </b-dropdown>
          </template>

          <!-- Details - Coverage Plans -->
          <template #row-details="row">
            <coverage-plans :region-coverages="row.item.coverages.data" />
          </template>
        </b-table>

        <b-pagination
          v-if="total > perPage"
          v-model="currentPage"
          class="float-right"
          :per-page="perPage"
          :total-rows="total"
          aria-controls="regions-table"
          @input="getRegions"
        />
      </b-overlay>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BTable, BButton, VBTooltip, BOverlay, BPagination, BDropdown, BDropdownItem, BFormGroup, BFormInput, BInputGroup, BInputGroupText,
} from 'bootstrap-vue'
import useApollo from '@/plugins/graphql/useApollo'
import _ from 'lodash'
import CreateEditRegion from './CreateEditRegion.vue'
import CoveragePlans from './CoveragePlans.vue'
import CreateEditCoveragePlans from './CreateEditCoveragePlans.vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BCard,
    BTable,
    BButton,
    BOverlay,
    BPagination,
    BDropdown,
    BDropdownItem,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupText,
    CoveragePlans,
    CreateEditRegion,
    CreateEditCoveragePlans,
  },
  props: {
    tabChanged: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      regions: [],
      total: 0,
      currentPage: 1,
      perPage: 10,
      isLoading: false,
      fields: [
        { key: 'id', label: 'S.No.' },
        'city', 'country', 'coverage_types',
        { key: 'actions', label: 'Actions', thClass: 'w-10' },
      ],
      searchKeyword: '',
      selectedRegion: null,
      updatingRegion: false,
      updatingCoveragePlans: false,
    }
  },
  watch: {
    tabChanged(val) {
      if (val) this.updatingCoveragePlans = false
    },
  },
  mounted() {
    this.getRegions()
  },
  methods: {
    showDetails(row) {
      if (row.coverages.total) {
        // eslint-disable-next-line no-underscore-dangle
        if (row._showDetails) {
          this.$set(row, '_showDetails', false)
        } else {
          this.regions.forEach(item => {
            this.$set(item, '_showDetails', false)
          })

          this.$nextTick(() => {
            this.$set(row, '_showDetails', true)
          })
        }
      }
    },
    searchRegion: _.debounce(function () {
      this.getRegions()
    }, 1000),
    getRegions() {
      this.isLoading = true
      useApollo.calculator.getRegions({
        first: this.perPage,
        page: this.currentPage,
        keyword: this.searchKeyword,
      }).then(response => {
        const { regions } = response.data
        this.total = regions.total
        this.regions = regions.data
      }).finally(() => {
        this.isLoading = false
        this.$emit('no-lazy')
      })
    },
    showEditRegionModal(region) {
      this.selectedRegion = region
      this.updatingRegion = true
      this.$nextTick(() => { this.selectedRegion = null })
    },
    showCoverageModal(region) {
      this.selectedRegion = region
      this.$nextTick(() => { this.updatingCoveragePlans = true })
    },
    onRegionUpdated() {
      this.updatingRegion = false
      this.selectedRegion = null
      this.getRegions()
    },
  },
}
</script>
