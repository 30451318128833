<template>
  <b-card class="mb-0">
    <div
      v-for="(coverages, category) in groupedCoverages"
      :key="category.id"
    >
      <h5>Coverage Plan - {{ category }} ({{ coverages.length }})</h5>
      <b-table
        :items="coverages"
        :fields="coverageFields"
        class="mb-2"
        striped
        responsive
        show-empty
      >
        <template #head()="data">
          <span class="text-capitalize">{{ data.label }}</span>
        </template>

        <template #cell(low)="data">
          <span>{{ formatAmount(data.item.low) }}</span>
        </template>

        <template #cell(mid)="data">
          <span>{{ formatAmount(data.item.mid) }}</span>
        </template>

        <template #cell(high)="data">
          <span>{{ formatAmount(data.item.high) }}</span>
        </template>
      </b-table>
      <hr>
    </div>
  </b-card>
</template>

<script>
import {
  BTable, BCard,
} from 'bootstrap-vue'

export default {
  components: {
    BTable,
    BCard,
  },
  props: {
    regionCoverages: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      coverageFields: [
        'minAge', 'maxAge', 'low', 'mid', 'high',
      ],
      regions: [],
      total: 0,
      perPage: 10,
      currentPage: 1,
    }
  },
  computed: {
    groupedCoverages() {
      return this.regionCoverages.reduce((r, a) => {
      // eslint-disable-next-line no-param-reassign
        r[a.category.name] = r[a.category.name] || []
        r[a.category.name].push(a)
        return r
      }, Object.create(null))
    },
  },
}
</script>
