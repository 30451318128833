<template>
  <div class="coverage-types">
    <b-card>
      <div class="d-flex justify-content-between align-items-baseline mb-2">
        <h4>Coverage Types</h4>

        <b-button
          variant="primary"
          @click="() => { $bvModal.show('coverage-type-modal') }"
        >
          <feather-icon icon="PlusIcon" />
          Add New Type
        </b-button>
      </div>

      <b-overlay :show="isLoading">
        <b-table
          :items="coverageTypes"
          :fields="fields"
          striped
          responsive
          show-empty
        >
          <template #head()="data">
            <span class="text-capitalize">{{ data.label }}</span>
          </template>

          <template #cell(id)="data">
            <span>{{ data.index + 1 }}</span>
          </template>

          <!-- Regions -->
          <template #cell(regions)="data">
            <span class="ml-2">{{ data.item.regions.total }}</span>
          </template>

          <!-- Actions -->
          <template #cell(actions)="data">
            <b-dropdown
              variant="link"
              no-caret
            >
              <template #button-content>
                <feather-icon icon="MoreVerticalIcon" />
              </template>

              <b-dropdown-item
                @click="showEditTypeModal(data.item)"
              >
                <feather-icon icon="Edit2Icon" />
                Update Coverage Type
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
      </b-overlay>
    </b-card>

    <b-modal
      id="coverage-type-modal"
      :title="`${form.id ? 'Update' : 'Create'} Coverage Type`"
      :ok-title="form.id ? 'Update' : 'Create'"
      :ok-disabled="isProcessing"
      :cancel-disabled="isProcessing"
      @hidden="() => { form = {} }"
      @ok="onSubmit"
    >
      <b-overlay :show="isProcessing">
        <validation-observer ref="coverageTypeForm">
          <validation-provider
            #default="{ errors }"
            rules="required"
            name="name"
          >
            <b-form-group label="Name">
              <b-form-input
                v-model="form.name"
                placeholder="Coverage Type Name"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </validation-observer>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BButton, BTable, BOverlay, BModal, BFormGroup, BFormInput, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import useApollo from '@/plugins/graphql/useApollo'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
  components: {
    BCard,
    BButton,
    BTable,
    BOverlay,
    BModal,
    BFormGroup,
    BFormInput,
    BDropdown,
    BDropdownItem,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      coverageTypes: [],
      fields: ['id', 'name', 'regions', { key: 'actions', tdClass: 'w-10' }],
      isLoading: false,
      isProcessing: false,
      form: {},
    }
  },
  mounted() {
    this.getCoverageTypes()
  },
  methods: {
    getCoverageTypes() {
      this.isLoading = true
      useApollo.calculator.getCoverageTypes().then(response => {
        this.coverageTypes = response.data.coverageTypes
        this.$emit('no-lazy')
      }).finally(() => { this.isLoading = false })
    },
    showEditTypeModal(type) {
      this.form = JSON.parse(JSON.stringify(type))
      this.$nextTick(() => { this.$bvModal.show('coverage-type-modal') })
    },
    onSubmit(bvModal) {
      bvModal.preventDefault()
      this.$refs.coverageTypeForm.validate().then(success => {
        if (success) {
          this.isProcessing = true
          if (this.form.id) this.updateCoverageType()
          else this.createCoverageType()
        }
      })
    },
    createCoverageType() {
      useApollo.calculator.createCoverageCategory({
        name: this.form.name,
      }).then(() => {
        this.showSuccessMessage({
          data: {
            message: 'Coverage Type Created Successfully!',
          },
        })
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        this.getCoverageTypes()
        this.isProcessing = false
        this.$bvModal.hide('coverage-type-modal')
      })
    },
    updateCoverageType() {
      useApollo.calculator.updateCoverageCategory({
        id: this.form.id,
        name: this.form.name,
      }).then(() => {
        this.showSuccessMessage({
          data: {
            message: 'Coverage Type Updated Successfully!',
          },
        })
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        this.getCoverageTypes()
        this.isProcessing = false
        this.$bvModal.hide('coverage-type-modal')
      })
    },
  },
}
</script>
