<template>
  <div>
    <b-card>
      <div class="d-flex align-items-baseline">
        <h3 class="mb-2">
          Coverage Plans
          <span v-if="selectedRegion">
            <feather-icon icon="ChevronRightIcon" />
            {{ `${selectedRegion.country} - ${selectedRegion.city}` }}
          </span>
          <span v-if="selectedCoverageType">
            <feather-icon icon="ChevronRightIcon" />
            {{ selectedCoverageType }}
          </span>
        </h3>
      </div>

      <b-overlay :show="isLoading">
        <div v-if="!coverageTypes.length">
          <b-alert
            variant="warning"
            class="p-1"
            show
          >
            <div
              class="d-flex align-items-center cursor-pointer"
              @click="() => { $emit('update-coverage-types') }"
            >
              <feather-icon icon="AlertCircleIcon" />
              <p class="ml-1">
                There are no coverage types. Please click here to create coverage types first.
              </p>
            </div>
          </b-alert>

          <b-button @click="$emit('back')">
            Close
          </b-button>
        </div>

        <div v-else>
          <!-- Coverage Type Selection -->
          <div v-if="steps[currentStep] === 'coverage-type-selection'">
            <b-form-group
              label="Select a Coverage Type"
            >
              <b-form-radio-group
                v-model="form.coverageType"
                :options="coverageTypesOptions"
                button-variant="outline-primary"
                size="lg"
                buttons
              />
            </b-form-group>
          </div>

          <!-- Coverage Plans Form -->
          <validation-observer ref="coveragePlansForm">
            <!-- Coverages -->
            <div v-if="steps[currentStep] === 'coverage-plans'">
              <b-card
                v-for="coverage, index in form.coverages"
                :key="index"
                border-variant="secondary"
              >
                <div class="d-flex justify-content-between">
                  <h5>{{ `Coverage #${index + 1}` }}</h5>
                  <b-button
                    v-if="coverage.id || index > 0"
                    size="sm"
                    variant="danger"
                    @click="removeCoverage(index)"
                  >
                    <feather-icon
                      icon="Trash2Icon"
                    />
                  </b-button>
                </div>

                <!-- Ages -->
                <b-row class="mb-2">
                  <b-col cols="2">
                    <validation-provider
                      #default="{ errors }"
                      :rules="`required|min_value:${form.coverages[index-1] ? Number(form.coverages[index-1].maxAge) + 1 : 0}`"
                      name="minimum age"
                    >
                      <b-form-group label="Min Age">
                        <b-form-input
                          v-model="coverage.minAge"
                          type="number"
                          placeholder="Min Age"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <b-col cols="2">
                    <validation-provider
                      #default="{ errors }"
                      :rules="`required|min_value:${form.coverages[index].minAge}`"
                      name="maximum age"
                    >
                      <b-form-group label="Max Age">
                        <b-form-input
                          v-model="coverage.maxAge"
                          type="number"
                          placeholder="Max Age"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <!-- Coverages -->
                  <b-col>
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                      name="low coverage"
                    >
                      <b-form-group label="Low Coverage">
                        <b-input-group>
                          <template #prepend>
                            <b-input-group-text>
                              {{ currency }}
                            </b-input-group-text>
                          </template>
                          <b-form-input
                            v-model="coverage.low"
                            type="number"
                            placeholder="Low Coverage"
                          />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <b-col>
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                      name="mid coverage"
                    >
                      <b-form-group label="Mid Coverage">
                        <b-input-group>
                          <template #prepend>
                            <b-input-group-text>
                              {{ currency }}
                            </b-input-group-text>
                          </template>
                          <b-form-input
                            v-model="coverage.mid"
                            type="number"
                            placeholder="Mid Coverage"
                          />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <b-col>
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                      name="high coverage"
                    >
                      <b-form-group label="High Coverage">
                        <b-input-group>
                          <template #prepend>
                            <b-input-group-text>
                              {{ currency }}
                            </b-input-group-text>
                          </template>
                          <b-form-input
                            v-model="coverage.high"
                            type="number"
                            placeholder="High Coverage"
                          />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
              </b-card>
            </div>

            <!-- Add Coverage -->
            <b-button
              v-if="steps[currentStep] === 'coverage-plans'"
              variant="outline-primary"
              class="mb-2 mt-1"
              @click="addCoverage"
            >
              <feather-icon icon="PlusIcon" />
              Add Coverage
            </b-button>
          </validation-observer>

          <!-- Buttons -->
          <div class="d-flex float-right mb-2 mt-1">
            <!-- Back -->
            <b-button
              variant="secondary"
              class="mx-1"
              @click="steps[currentStep] === 'coverage-type-selection' ? $emit('back') : prev()"
            >
              {{ steps[currentStep] === 'coverage-type-selection' ? 'Cancel' : 'Back' }}
            </b-button>

            <!-- Next -->
            <b-button
              variant="primary"
              :disabled="nextDisabled"
              @click="next"
            >
              <span v-if="steps[currentStep] === 'coverage-plans'">Submit</span>
              <span v-else>Next</span>
            </b-button>
          </div>
        </div>
      </b-overlay>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BFormGroup, BFormRadioGroup, BOverlay, BButton, BFormInput, BInputGroup, BInputGroupText, BAlert,
} from 'bootstrap-vue'
import useApollo from '@/plugins/graphql/useApollo'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, min_value } from 'vee-validate/dist/rules'

export default {
  components: {
    BCard,
    BFormGroup,
    BFormRadioGroup,
    BOverlay,
    BButton,
    BFormInput,
    BInputGroup,
    BInputGroupText,
    BAlert,
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    selectedRegion: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      min_value,
      currency: this.$store.state.project.company.currency,
      regions: [],
      coverageTypes: [],
      form: {
        region: null,
        coverages: [],
      },
      steps: [
        'coverage-type-selection',
        'coverage-plans',
      ],
      currentStep: 0,
      isLoading: false,
      selectedCoverageType: null,
      selectedCoverage: null,
    }
  },
  computed: {
    regionOptions() {
      if (this.regions) {
        return this.regions.map(region => ({
          value: region.id,
          text: `${region.country} - ${region.city}`,
        }))
      }
      return []
    },
    coverageTypesOptions() {
      if (this.coverageTypes) {
        return this.coverageTypes.map(type => ({
          value: type.id,
          text: type.name,
        }))
      }
      return []
    },
    nextDisabled() {
      switch (this.steps[this.currentStep]) {
        case 'coverage-type-selection':
          return !this.form.coverageType
        default:
          return false
      }
    },
  },
  watch: {
    'form.region': {
      handler(val) {
        if (val) this.form.coverages = []
      },
    },
  },
  mounted() {
    this.getCoverageTypes()
  },
  methods: {
    next() {
      if (this.steps[this.currentStep] === 'coverage-plans') {
        this.$refs.coveragePlansForm.validate().then(success => {
          if (success) this.storeCoveragePlans()
        })
      } else {
        this.currentStep += 1

        this.$nextTick(() => {
          switch (this.steps[this.currentStep]) {
            case 'coverage-type-selection':
              if (!this.coverageTypesOptions.length) this.getCoverageTypes()
              // eslint-disable-next-line no-case-declarations
              const region = this.regions.find(r => r.id === this.form.region)
              this.selectedRegion = region ? `${region.country} - ${region.city}` : ''
              break
            default:
              this.selectedCoverageType = this.coverageTypes.find(c => c.id === this.form.coverageType)?.name ?? ''
              this.getCoveragesByCategory()
          }
        })
      }
    },
    addCoverage() {
      const coverage = {
        minAge: null,
        maxAge: null,
        low: 0,
        mid: 0,
        high: 0,
      }
      this.form.coverages.push(coverage)
    },
    removeCoverage(index) {
      this.form.coverages.splice(index, 1)
    },
    prev() {
      this.currentStep -= 1

      this.$nextTick(() => {
        switch (this.steps[this.currentStep]) {
          case 'coverage-type-selection':
            this.selectedCoverageType = null
            break
          default:
        }
      })
    },
    storeCoveragePlans() {
      this.isLoading = true
      // eslint-disable-next-line no-unused-vars
      const coverages = this.form.coverages.map(coverage => ({
        id: coverage.id ?? null,
        minAge: Number(coverage.minAge),
        maxAge: Number(coverage.maxAge),
        low: Number(coverage.low),
        mid: Number(coverage.mid),
        high: Number(coverage.high),
      }))

      const params = {
        region: this.selectedRegion.id,
        coverageCategory: this.form.coverageType,
        input: coverages,
      }

      useApollo.calculator.updateCoverages(params).then(response => {
        this.showSuccessMessage({
          data: {
            message: response.data.updateCoverages.message,
          },
        })
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        this.isLoading = false
        this.selectedCoverageType = null
        this.currentStep = 0
        this.$emit('coverage-updated')
      })
    },
    getCoverageTypes() {
      this.isLoading = true
      useApollo.calculator.getCoverageTypes().then(response => {
        this.coverageTypes = response.data.coverageTypes
      }).finally(() => { this.isLoading = false })
    },
    getCoveragesByCategory() {
      this.isLoading = true
      useApollo.calculator.getCoveragesByCategoryInRegion({
        regionId: this.selectedRegion.id,
        coverageCategoryId: this.form.coverageType,
      }).then(response => {
        const coverages = response.data.regions.data[0]?.coverageCategories?.data[0]?.coverages
        this.form.coverages = coverages?.data ?? []
        if (!coverages || !coverages.total) this.addCoverage()
      }).finally(() => {
        this.isLoading = false
      })
    },
  },
}
</script>
