<template>
  <b-card>
    <back-with-title
      :title="form.id ? 'Edit Region' : 'Create Region'"
      :callback="true"
      @back="() => { $emit('back'); clearForm() }"
    />

    <b-overlay :show="isLoading">
      <validation-observer ref="regionForm">
        <b-row>
          <b-col md="3">
            <!-- Country -->
            <validation-provider
              #default="{ errors }"
              rules="required"
              name="country"
            >
              <b-form-group label="Country">
                <b-form-input
                  v-model="form.country"
                  type="text"
                  placeholder="Country"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col md="3">
            <!-- City -->
            <validation-provider
              #default="{ errors }"
              rules="required"
              name="city"
            >
              <b-form-group label="City">
                <b-form-input
                  v-model="form.city"
                  type="text"
                  placeholder="City"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
      </validation-observer>

      <div class="mb-2 mt-1">
        <b-button
          variant="primary"
          @click="updateRegion"
        >
          {{ form.id ? 'Update' : 'Create' }}
        </b-button>
      </div>
    </b-overlay>
  </b-card>
</template>

<script>
import {
  BCard, BFormGroup, BFormInput, BButton, BRow, BCol, BOverlay,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required, min_value } from 'vee-validate/dist/rules'
import BackWithTitle from '@/views/common/components/BackWithTitle.vue'
import useApollo from '@/plugins/graphql/useApollo'

export default {
  components: {
    BCard,
    BFormGroup,
    BFormInput,
    BButton,
    BRow,
    BCol,
    BOverlay,
    ValidationObserver,
    ValidationProvider,
    BackWithTitle,
  },
  props: {
    region: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      required,
      min_value,
      form: {},
      categories: [
        { value: null, text: 'Select a Category' },
        { value: 'HEALTHINSURANCE', text: 'Health Insurance' },
        { value: 'RETIREMENT', text: 'Retirement' },
      ],
      isLoading: false,
    }
  },
  watch: {
    region: {
      handler(val) {
        if (val) {
          this.form = val
        }
      },
      immediate: true,
    },
  },
  methods: {
    updateRegion() {
      this.$refs.regionForm.validate().then(success => {
        if (success) {
          this.isLoading = true

          const params = {
            input: {
              country: this.form.country,
              city: this.form.city,
            },
          }
          if (this.form.id) params.id = this.form.id

          useApollo.calculator.updateRegion(params).then(response => {
            this.showSuccessMessage({
              data: {
                message: response.data.updateRegion.message,
              },
            })
          }).catch(error => {
            this.showErrorMessage(error)
          }).finally(() => {
            this.isLoading = false
            this.$emit('region-updated')
          })
        }
      })
    },
    clearForm() {
      this.form = {
        region: '',
        coverages: [],
      }
    },
  },
}
</script>
